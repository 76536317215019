@charset "UTF-8";
/* Theme Fonts */
@font-face {
  font-family: "payroll-icons";
  src: url("../../fonts/payroll-icons.eot?94887748");
  src: url("../../fonts/payroll-icons.eot?94887748#iefix") format("embedded-opentype"), url("../../fonts/payroll-icons.woff2?94887748") format("woff2"), url("../../fonts/payroll-icons.woff?94887748") format("woff"), url("../../fonts/payroll-icons.ttf?94887748") format("truetype"), url("../../fonts/payroll-icons.svg?94887748#payroll-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/* Styling */
body {
  /* Misc. */
  /* Breakpoints */
}
body .payroll-site {
  min-height: 100vh;
}
body header .payweek-button,
body header .payweek-label {
  background: transparent;
  font-size: 2rem;
  padding: 0.25rem 0;
}
body header .payweek-button:active, body header .payweek-button:hover,
body header .payweek-label:active,
body header .payweek-label:hover {
  background: transparent;
}
body header .payweek-button > i,
body header .payweek-label > i {
  position: relative;
  top: 0.25rem;
  margin: 0;
}
body header .payweek-button > span,
body header .payweek-label > span {
  position: relative;
  top: 0.05rem;
  line-height: 1;
}
body header span.payweek-label,
body header button.payweek-label {
  width: 310px;
  text-align: center;
}
body header span.payweek-label {
  display: inline-block;
  line-height: 1;
}
body header .header-nav {
  text-align: left;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid;
}
body .login-screen {
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
body .login-screen .login-logo {
  height: 80px;
}
body .login-screen h1 {
  white-space: nowrap;
}
body .payroll-content h2 {
  margin: 0;
}
body .payroll-content h2.section-header {
  display: flex;
  position: relative;
  align-items: center;
}
body .payroll-content h2.section-header > * {
  margin-left: 0.25rem;
}
body .payroll-content h2.section-header > .button, body .payroll-content h2.section-header > form > .button {
  margin: 0.5rem 0.25rem;
  font-size: 0.6rem;
}
body .payroll-content h2.section-header > .button.scroll-to-header, body .payroll-content h2.section-header > form > .button.scroll-to-header {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0;
}
body .payroll-content h2.flex-container .hint-text {
  font-size: 1rem;
  align-self: center;
}
body .payroll-content h2.flex-container + hr {
  margin-top: 1.25rem;
  margin-bottom: 0;
  max-width: unset;
}
body .payroll-content h2 > .h2Form {
  display: inline-block;
}
body .payroll-content h2 > button, body .payroll-content h2 > .h2Form > .button {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}
body .payroll-content hr {
  margin: 0;
  max-width: unset;
  border-bottom: 2px solid #f5f5f5;
}
body .payroll-content .payWeek-table {
  margin: 0;
  border: 2px solid;
}
body .payroll-content .payWeek-table thead {
  background: transparent;
}
body .payroll-content .payWeek-table tbody {
  background: transparent;
}
body .payroll-content .payWeek-table tbody tr {
  background: transparent;
}
body .payroll-content .payWeek-table tbody tr td {
  border: 2px solid;
  padding: 0;
  cursor: pointer;
  width: 15%;
}
body .payroll-content .payWeek-table tbody tr td.weekend {
  max-width: 5%;
}
body .payroll-content .payWeek-table tbody tr td.today .dateDetails .dateNum {
  padding: 0 0.25rem;
  border-radius: 100%;
}
body .payroll-content .payWeek-table tbody tr td .weekHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  border-right: 1px solid;
  font-weight: bold;
}
body .payroll-content .payWeek-table tbody tr td .dateDetails {
  padding: 0.5rem;
}
body .payroll-content .payWeek-table tbody tr td .dateDetails .dateNum {
  font-size: 1.5rem;
  float: left;
}
body .payroll-content .payWeek-table tbody tr td .dateDetails .dailyHours,
body .payroll-content .payWeek-table tbody tr td .dateDetails .dailyExpenses {
  display: block;
  text-align: right;
}
body .payroll-content .payWeek-table tbody tr td .dateDetails .absent {
  clear: both;
}
body .payroll-content .payWeek-table tbody tr td .dateDetails .absent .label,
body .payroll-content .payWeek-table tbody tr td .dateDetails .absent .badge {
  margin-right: 0.25rem;
  border: 2px solid;
  width: 25px;
  height: 25px;
}
body .payroll-content .payWeek-table.summary thead tr th[class^=week] {
  text-align: center;
}
body .payroll-content .payWeek-table.summary tbody tr th {
  white-space: nowrap;
}
body .payroll-content .payWeek-table.summary tbody tr td {
  text-align: center;
  max-width: 5%;
}
body .payroll-content .payWeek-table.summary tbody tr td.weekend {
  max-width: 1%;
}
body .payroll-content .payWeek-table.summary tbody tr td .tooltip-placeholder {
  display: block;
  line-height: 2.5rem;
  cursor: pointer;
}
body .payroll-content .weekSummary .weekTotals,
body .payroll-content .timeoffSummary .weekTotals {
  border: 2px solid #f5f5f5;
  margin: 1rem 0;
}
body .payroll-content .weekSummary .weekTotals h3,
body .payroll-content .timeoffSummary .weekTotals h3 {
  margin: 0;
  line-height: 1;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.25rem;
}
body .payroll-content .weekSummary .weekTotals span,
body .payroll-content .timeoffSummary .weekTotals span {
  padding: 0.25rem;
}
body .payroll-content .weekSummary .weekTotals hr,
body .payroll-content .timeoffSummary .weekTotals hr {
  margin: 0.5rem 0;
}
body .payroll-content .weekSummary > .hint-text,
body .payroll-content .timeoffSummary > .hint-text {
  position: relative;
  bottom: 0.5rem;
}
body .payroll-content .pto-totals {
  margin-top: 1rem;
}
body .payroll-content .pto-totals ul {
  margin: 2.1rem 0;
  text-indent: 1rem;
  list-style: none;
  display: inline-block;
}
body .payroll-content .pto-totals ul li hr {
  margin-top: 1rem;
}
body .payroll-content .pto-totals div.pto-personal {
  margin: 0.75rem 0 2rem 0.75rem;
  display: inline-block;
  padding: 0.5rem;
}
body .payroll-content .pto-totals div.pto-personal h3 .button {
  margin-bottom: 0;
}
body .payroll-content .pto-totals div.pto-personal div.current-requests {
  border-top: 2px solid;
  min-height: 1.5rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
}
body .payroll-content .pto-totals div.pto-personal div.current-requests .checkbox-switch-container {
  margin-top: 0.5rem;
  border: 1px solid;
}
body .payroll-content .pto-totals div.pto-personal div.current-requests .checkbox-switch-container .checkbox-switch {
  left: 0;
}
body .payroll-content .search-options {
  border-bottom: 2px solid;
  margin-bottom: 1rem;
}
body .payroll-content .time-entry .hint-text,
body .payroll-content .result-entry .hint-text,
body .payroll-content .expense-entry .hint-text {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.75rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  opacity: 0.75;
}
body .payroll-content .time-entry .hint-text > .button,
body .payroll-content .result-entry .hint-text > .button,
body .payroll-content .expense-entry .hint-text > .button {
  padding: 0;
  margin: 0 0 0.25rem;
  border: none;
}
body .payroll-content .time-entry .mark-for-removal,
body .payroll-content .result-entry .mark-for-removal,
body .payroll-content .expense-entry .mark-for-removal {
  display: inline-block;
  position: relative;
}
body .payroll-content .time-entry .mark-for-removal > .hint-text,
body .payroll-content .result-entry .mark-for-removal > .hint-text,
body .payroll-content .expense-entry .mark-for-removal > .hint-text {
  top: 0.25rem;
  left: 2.5rem;
  right: unset;
  white-space: nowrap;
}
body .payroll-content .time-entry label.button,
body .payroll-content .time-entry button,
body .payroll-content .result-entry label.button,
body .payroll-content .result-entry button,
body .payroll-content .expense-entry label.button,
body .payroll-content .expense-entry button {
  border: 1px solid #555555;
  margin-right: 0.5rem;
}
body .payroll-content .time-entry label.button.copy-anywhere,
body .payroll-content .time-entry button.copy-anywhere,
body .payroll-content .result-entry label.button.copy-anywhere,
body .payroll-content .result-entry button.copy-anywhere,
body .payroll-content .expense-entry label.button.copy-anywhere,
body .payroll-content .expense-entry button.copy-anywhere {
  margin-right: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
body .payroll-content .time-entry dl,
body .payroll-content .result-entry dl,
body .payroll-content .expense-entry dl {
  display: flex;
  flex-wrap: wrap;
}
body .payroll-content .time-entry dl dt,
body .payroll-content .result-entry dl dt,
body .payroll-content .expense-entry dl dt {
  flex-basis: 10%;
  margin: 0;
  line-height: 1rem;
}
body .payroll-content .time-entry dl dd,
body .payroll-content .result-entry dl dd,
body .payroll-content .expense-entry dl dd {
  flex-basis: 90%;
  line-height: 1rem;
}
body .payroll-content .time-entry fieldset,
body .payroll-content .result-entry fieldset,
body .payroll-content .expense-entry fieldset {
  border: 1px inset;
  padding: 0 1rem 0.5rem 2rem;
}
body .payroll-content .time-entry fieldset legend,
body .payroll-content .result-entry fieldset legend,
body .payroll-content .expense-entry fieldset legend {
  margin: 0 0 0 -1.5rem;
  padding: 0 0.5rem;
  font-weight: bold;
}
body .payroll-content .time-entry.offset button,
body .payroll-content .result-entry.offset button,
body .payroll-content .expense-entry.offset button {
  border: 1px solid #767676;
}
body .payroll-content .settings-form label.color-option {
  display: inline-block;
  min-width: 30%;
  margin-right: 1rem;
}
body .payroll-content .settings-form label.checkbox-switch {
  margin-bottom: 0;
}
body .payroll-content .settings-form .callout > label {
  display: flex;
  align-items: center;
  font-size: 1rem;
}
body .payroll-content .settings-form .callout > label > span {
  white-space: nowrap;
  flex-basis: 25%;
}
body .payroll-content .settings-form .callout > label > input, body .payroll-content .settings-form .callout > label > select {
  margin: 0;
}
body .payroll-content .settings-form #light_or_dark {
  font-size: 1.5rem;
  bottom: 3px;
  position: relative;
  vertical-align: middle;
}
body .payroll-content .settings-form .label-text {
  margin-left: 0.5rem;
  vertical-align: middle;
}
body .payroll-content .settings-form .reset-help {
  margin: 0 0 1rem 1rem;
  font-size: 1rem;
  line-height: 2.5rem;
  display: inline-block;
  vertical-align: top;
}
body .payroll-content .settings-form #resetContainer {
  margin-top: 1rem;
}
body .payroll-content .settings-form #resetContainer + hr {
  margin-bottom: 1rem;
}
body .payroll-content .settings-form #updateNotice {
  border-bottom: 2px solid;
  padding-bottom: 1rem;
}
body .payroll-content .search-form {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-bottom: 2px solid;
}
body .payroll-content .search-form h3 {
  margin: 0;
  line-height: 1;
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 0.25rem;
}
body .payroll-content .search-form hr {
  width: calc(100% + 1rem);
  margin: 0.5rem -0.5rem;
}
body .payroll-content .search-results .debug-panel {
  position: relative;
  top: 1rem;
}
body .payroll-content .search-results .contract-block > .title {
  display: inline-block;
  padding: 0.5rem;
  border-radius: 15px 15px 0 0;
}
body .payroll-content .search-results .contract-block .contract-table .sub-header {
  text-align: left;
}
body .payroll-content .search-results .contract-block .contract-table td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
body .payroll-content .search-results .contract-block .contract-table tr.table-break > td {
  height: 1rem;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
body .payroll-content .tabs {
  background: transparent;
  border: none;
  margin-top: 1rem;
}
body .payroll-content .tabs > .tabs-title > a {
  border-radius: 15px 15px 0 0;
  position: relative;
  top: 2px;
  z-index: 4;
  border: 1px solid #f5f5f5;
  border-left: 0;
}
body .payroll-content .tabs > .tabs-title.is-active > a {
  padding-bottom: calc(1.25rem + 1px);
  border-left: 1px solid #f5f5f5;
  z-index: 6;
}
body .payroll-content .tabs-content {
  border: 1px solid #f5f5f5;
  position: relative;
  z-index: 5;
}
body .payroll-content .tabs-content dl {
  margin: 0;
}
body .payroll-content .tabs-content dl dt,
body .payroll-content .tabs-content dl dd {
  display: inline-block;
  width: 25%;
  vertical-align: top;
}
body .payroll-content .tabs-content dl dd {
  width: 74%;
  border: 2px solid #1c1c1c;
}
body .payroll-content .tabs-content dl dd.has-table {
  padding: 0;
  border: 0;
}
body .payroll-content .tabs-content dl dd.has-table table {
  margin-bottom: 0;
}
body .payroll-content .tabs-content dl > dd,
body .payroll-content .tabs-content .inset-panel {
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid #f5f5f5;
  padding: 0.25rem;
  margin: 0 0 0.5rem;
}
body .payroll-content .tabs-content .accordion {
  background: transparent;
}
body .payroll-content .tabs-content .accordion .accordion-title {
  font-size: 1.25rem;
}
body .payroll-content .tabs-content .accordion .accordion-content {
  background: transparent;
}
body .payroll-content .tabs-content .accordion .accordion-content.has-table {
  padding: 0;
}
body .payroll-content .tabs-content .accordion .accordion-content.has-table table {
  margin-bottom: 0;
}
body .payroll-content .tabs-content .archive-flex {
  display: flex;
  align-items: center;
}
body .payroll-content .tabs-content .archive-flex .archive-count {
  font-size: 1.5rem;
  margin-left: 0.5rem;
}
body .payroll-content .tabs-content .archive-flex .archive-count.standard {
  color: #9ac153;
}
body .payroll-content .tabs-content .archive-flex .archive-count.alert {
  color: #fd5d65;
}
body .payroll-content .tabs-content .archive-table .no-wrap {
  white-space: nowrap;
}
body #back-to-top {
  padding-right: 1rem;
  pointer-events: none;
}
body #back-to-top > a {
  pointer-events: auto;
}
body .reveal > * {
  max-width: 600px;
  margin: 0 auto;
}
body .reveal.reveal-opened {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}
body .reveal h2 {
  padding: 0 1rem;
  border: 1px solid;
  width: 100%;
}
body .reveal .close-button-container {
  position: relative;
  width: 100%;
}
body .reveal > form {
  border: 1px solid;
  width: 100%;
}
body .reveal > form.short-reveal-form {
  padding-top: 1rem;
}
body .reveal > form.short-reveal-form > div.buttons {
  margin: 0 1rem;
}
body .reveal > form > div.buttons .button {
  border: 1px solid;
}
body .reveal .abide-error {
  display: inline-block !important;
  vertical-align: middle;
  margin: 0 0 1rem 0.5rem;
}
body .reveal .abide-error > .form-error {
  margin: 0;
}
body .reveal .accordion .accordion-item .accordion-title .sub-text {
  float: right;
  margin-right: 1rem;
}
body .reveal .accordion .accordion-item .accordion-content .label-cell {
  align-self: center;
}
body .reveal .accordion .accordion-item .accordion-content .label-cell label {
  margin-bottom: 0.5rem;
}
body .reveal .accordion .accordion-item .accordion-content .field-cell {
  margin-bottom: 0.5rem;
}
body .reveal .accordion .accordion-item .accordion-content .field-cell .button,
body .reveal .accordion .accordion-item .accordion-content .field-cell textarea,
body .reveal .accordion .accordion-item .accordion-content .field-cell .input-group,
body .reveal .accordion .accordion-item .accordion-content .field-cell input[type=text],
body .reveal .accordion .accordion-item .accordion-content .field-cell input[type=number] {
  margin-bottom: 0;
}
body .reveal .accordion .accordion-item .accordion-content .button {
  border: 1px solid;
}
body .reveal .accordion .accordion-item.is-active .accordion-title {
  cursor: default;
}
body #requestPTOEntry .checkbox-switch-container {
  float: left;
  margin-bottom: 3px;
}
body #requestPTOEntry .checkbox-switch-container .checkbox-switch {
  border: 2px solid;
}
body #requestPTOEntry .pto-table-spacing {
  margin-bottom: 1px;
}
body #requestPTOEntry .pto-table-spacing > label {
  margin-bottom: 0;
}
body #requestPTOEntry .pto-table-notes {
  margin-top: 0.5rem;
}
body #requestPTOEntry .pto-usage-table {
  margin-left: 2.3rem;
  margin-bottom: 0;
  width: calc(100% - 2.3rem);
}
body #requestPTOEntry .pto-usage-table th {
  font-size: 0.8rem;
  width: 33%;
  text-align: right;
}
body #requestPTOEntry .pto-usage-table td {
  width: 33%;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: right;
}
body #requestPTOEntry .pto-usage-table.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
body #requestPTOEntry .pto_request_type_m_desc {
  width: calc(100% - 2.3rem);
  padding-top: 1px;
  padding-bottom: 1px;
  height: auto;
}
body #loadingScreen {
  max-width: unset;
  width: 100%;
  height: 100vh;
  top: 0 !important;
  border: none;
  justify-content: center;
  align-items: center;
}
body #loadingScreen.is-open {
  display: block;
}
body #loadingScreen > div.loading-icon {
  font-size: 3.5rem;
}
body #loadingScreen > div.loading-icon::after {
  content: "Loading";
  display: block;
  font-size: 1rem;
  text-align: center;
}
body label.button {
  padding: calc(0.5rem - 2px);
}
body .checkbox-switch-container {
  position: relative;
}
body .checkbox-switch-container > label.checkbox-switch {
  position: relative;
  z-index: 2;
  left: 1px;
}
body .checkbox-switch-container > input[type=checkbox] {
  scale: 1.75;
  margin: 0;
}
body .checkbox-switch-container > span.help-text {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  top: calc(0.5rem - 1px);
  left: 0;
  padding: calc(0.25rem - 1px);
  z-index: 1;
  cursor: pointer;
  line-height: 1.35rem;
  border: 1px solid #3B6B24;
}
body .checkbox-switch-container.disabled {
  opacity: 0.5;
}
body .checkbox-switch-container.disabled > label.checkbox-switch {
  cursor: not-allowed;
}
body .number-spinner {
  position: relative;
}
body .number-spinner > .spinner-button {
  position: absolute;
  right: 0;
  top: 1px;
  margin: 0;
  padding: 0.25rem 0.5rem;
  border: 1px solid;
}
body .number-spinner > .spinner-button.down {
  top: unset;
  bottom: 1px;
}
body .flatpickr-calendar .flatpickr-time > .numInputWrapper,
body .flatpickr-calendar .flatpickr-current-month > .numInputWrapper {
  width: unset;
  position: relative;
}
body .flatpickr-calendar .flatpickr-time > .numInputWrapper > .arrowUp, body .flatpickr-calendar .flatpickr-time > .numInputWrapper > .arrowDown,
body .flatpickr-calendar .flatpickr-current-month > .numInputWrapper > .arrowUp,
body .flatpickr-calendar .flatpickr-current-month > .numInputWrapper > .arrowDown {
  position: absolute;
  right: 1px;
  top: 1px;
  margin: 0;
  padding: 0.35rem;
  border: 1px solid;
  opacity: 1;
  width: 1.25rem;
}
body .flatpickr-calendar .flatpickr-time > .numInputWrapper > .arrowUp.arrowDown, body .flatpickr-calendar .flatpickr-time > .numInputWrapper > .arrowDown.arrowDown,
body .flatpickr-calendar .flatpickr-current-month > .numInputWrapper > .arrowUp.arrowDown,
body .flatpickr-calendar .flatpickr-current-month > .numInputWrapper > .arrowDown.arrowDown {
  top: unset;
  bottom: 1px;
}
body .flatpickr-calendar .flatpickr-time .flatpickr-time-separator {
  padding: 0 1rem 0 0.9rem;
}
body .flatpickr-calendar .flatpickr-current-month {
  padding: 0;
  display: flex;
  justify-content: space-between;
}
body .flatpickr-calendar .flatpickr-current-month > select {
  height: inherit;
  flex-grow: 1;
}
body .flatpickr-calendar .flatpickr-current-month > .numInputWrapper > .cur-year {
  line-height: unset;
  height: 100%;
}
body .flatpickr-calendar .flatpickr-prev-month svg,
body .flatpickr-calendar .flatpickr-next-month svg {
  position: relative;
  bottom: 3px;
}
body .caution-text {
  display: block;
  margin: 1rem 0;
  border: 2px solid;
}
body .pr-flatpickr-input[readonly] {
  cursor: default;
}
body .flatpickr-monthDropdown-months {
  width: auto;
}
body .pay-icon {
  font-family: "payroll-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  /* '' */
  /* Icon List */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
}
body .pay-icon.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
body .pay-icon.icon-empty:before {
  color: transparent !important;
  content: "\e80b";
}
body .pay-icon.icon-user:before {
  content: "\e800";
}
body .pay-icon.icon-cancel:before {
  content: "\e801";
}
body .pay-icon.icon-help:before {
  content: "\e802";
}
body .pay-icon.icon-info:before {
  content: "\e803";
}
body .pay-icon.icon-alert:before {
  content: "\e804";
}
body .pay-icon.icon-down:before {
  content: "\e805";
}
body .pay-icon.icon-left:before {
  content: "\e806";
}
body .pay-icon.icon-right:before {
  content: "\e807";
}
body .pay-icon.icon-up:before {
  content: "\e808";
}
body .pay-icon.icon-settings:before {
  content: "\e809";
}
body .pay-icon.icon-location:before {
  content: "\e80a";
}
body .pay-icon.icon-check:before {
  content: "\e80b";
}
body .pay-icon.icon-trash:before {
  content: "\e80c";
}
body .pay-icon.icon-plus:before {
  content: "\e80d";
}
body .pay-icon.icon-minus:before {
  content: "\e80e";
}
body .pay-icon.icon-switch:before {
  content: "\e80f";
}
body .pay-icon.icon-key:before {
  content: "\e810";
}
body .pay-icon.icon-calendar:before {
  content: "\e811";
}
body .pay-icon.icon-edit:before {
  content: "\e812";
}
body .pay-icon.icon-flag:before {
  content: "\e813";
}
body .pay-icon.icon-clock:before {
  content: "\e814";
}
body .pay-icon.icon-logout:before {
  content: "\e816";
}
body .pay-icon.icon-search:before {
  content: "\e817";
}
body .pay-icon.icon-sun:before {
  content: "\e819";
}
body .pay-icon.icon-moon:before {
  content: "\e81a";
}
body .pay-icon.icon-maint:before {
  content: "\e81b";
}
body .pay-icon.icon-group:before {
  content: "\e81c";
}
body .pay-icon.icon-loading:before {
  content: "\e834";
}
body .pay-icon.icon-copy:before {
  content: "\f0c5";
}
body .pay-icon.icon-magic:before {
  content: "\f0d0";
}
body .pay-icon.icon-paste:before {
  content: "\f0ea";
}
body .pay-icon.icon-file-basic:before {
  content: "\f0f6";
}
body .pay-icon.icon-dollar:before {
  content: "\f155";
}
body .pay-icon.icon-file-pdf:before {
  content: "\f1c1";
}
body .pay-icon.icon-file-image:before {
  content: "\f1c5";
}
body .pay-icon.icon-copyright:before {
  content: "\f1f9";
}
body .pay-icon.icon-lock:before {
  content: "\f510";
}
body .debug-panel {
  max-height: 75vh;
  height: fit-content;
  overflow: hidden auto;
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(28, 28, 28, 0.25);
  border-radius: 0;
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
body .debug-panel > :first-child {
  margin-top: 0;
}
body .debug-panel > :last-child {
  margin-bottom: 0;
}
body .debug-panel.thin {
  padding: 0.5rem;
  width: auto;
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0;
}
body .debug-panel.alert {
  background-color: #fec6c9;
  color: #1c1c1c;
}
body .debug-panel.warning {
  background-color: #ffd8a6;
  color: #1c1c1c;
}
body .debug-panel.highlight {
  background-color: #fff3c4;
  color: #1c1c1c;
}
body .debug-panel.primary {
  background-color: #a1d3f3;
  color: #1c1c1c;
}
body .debug-panel.success {
  background-color: #dce9c3;
  color: #1c1c1c;
}
body .debug-panel.info {
  background-color: #c4e7ff;
  color: #1c1c1c;
}
body .debug-panel.misc {
  background-color: #dacefc;
  color: #1c1c1c;
}
body .debug-panel.secondary {
  background-color: #cfcfcf;
  color: #1c1c1c;
}
body .color-swatch {
  border: 1px solid #000;
}
body .color-swatch.white {
  background: #f5f5f5;
}
body .color-swatch.light {
  background: #b0b0b0;
}
body .color-swatch.medium {
  background: #828282;
}
body .color-swatch.dark {
  background: #555555;
}
body .color-swatch.black {
  background: #1c1c1c;
}
body select[size] {
  height: unset;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
body select > optgroup {
  color: transparent;
  line-height: 0;
  font-size: 0;
}
body select > optgroup > option {
  color: #1c1c1c;
  font-size: 1rem;
  padding-left: 0.25rem;
}
body input[type=text]:disabled,
body input[type=date]:disabled,
body input[type=color]:disabled,
body input[type=number]:disabled,
body input[type=password]:disabled,
body textarea:disabled,
body select:disabled {
  opacity: 0.5;
}
body .callout {
  margin: 1rem 0;
}
body table thead {
  border: none;
}
body table thead th {
  border: 2px solid #1c1c1c;
}
body table thead th.empty-cell {
  border: none;
}
body table tbody {
  border: 2px solid #1c1c1c;
}
body table tbody tr:not(:first-child) th {
  border-top: 2px solid #f5f5f5;
}
body table tbody tr:not(:first-child) td {
  border-top: 2px solid #1c1c1c;
}
body table tbody th {
  border: 2px solid;
}
body table tbody th,
body table tbody td.numeric {
  text-align: right;
}
body table tbody td:not(:first-child) {
  border-left: 2px solid #1c1c1c;
}
body footer {
  margin-top: 1rem;
}
@media print, screen and (max-width: 39.99875em) {
  body header > form:not(.header-nav) {
    text-align: center;
    padding: 0 !important;
  }
  body header .payweek-button,
body header .payweek-label {
    font-size: clamp(1rem, 8vw, 2rem);
  }
  body header span.payweek-label,
body header button.payweek-label {
    width: clamp(250px, 80%, 300px);
  }
  body .login-screen form {
    max-width: min-content;
    min-width: 95vw;
  }
  body .payroll-content .payWeek-table tbody tr td {
    width: 100%;
  }
  body .payroll-content .payWeek-table tbody tr td.weekend {
    max-width: unset;
  }
  body .payroll-content .payWeek-table tbody tr td .dateDetails {
    width: calc(100% - 50px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  body .payroll-content .payWeek-table tbody tr td .dateDetails .dateNum {
    font-size: 1.25rem;
  }
  body .payroll-content .payWeek-table tbody tr td .dateDetails .dailyHours {
    order: 2;
    flex-grow: 1;
  }
  body .payroll-content .payWeek-table tbody tr td .dateDetails .dailyExpenses {
    order: 3;
    border-left: 2px solid;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
  }
  body .payroll-content .payWeek-table tbody tr td .dateDetails .dailyExpenses.empty {
    border-left: none;
    margin: 0;
    padding: 0;
  }
  body .payroll-content .time-entry dl,
body .payroll-content .result-entry dl,
body .payroll-content .expense-entry dl {
    display: block;
  }
  body .payroll-content .time-entry dl dt,
body .payroll-content .result-entry dl dt,
body .payroll-content .expense-entry dl dt {
    float: left;
  }
  body .payroll-content .time-entry dl dd,
body .payroll-content .result-entry dl dd,
body .payroll-content .expense-entry dl dd {
    flex-basis: 100%;
    text-align: right;
  }
  body .payroll-content .tabs > .tabs-title > a {
    padding: 1.25rem 0.75rem;
  }
  body .payroll-content .tabs-content dl dt,
body .payroll-content .tabs-content dl dd {
    width: 100%;
  }
  body .reveal .field-cell .checkbox-switch-container > label.checkbox-switch {
    margin-bottom: 0.5rem !important;
  }
  body .checkbox-switch-container > span.help-text {
    position: relative;
    top: -0.25rem;
    display: inline-block;
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0;
    margin-bottom: 0.5rem !important;
  }
  body .close-button {
    font-size: 1rem;
    top: 0.5rem;
    right: 0.25rem;
  }
}
@media print, screen and (min-width: 40em) {
  body .login-screen form {
    max-width: min-content;
  }
  body .payroll-content .tabs > .tabs-title > a {
    padding: 1.25rem 0.75rem;
  }
}