@import 'settings';
@import 'components/callout';
@import 'components/button';
@import 'xy-grid/xy-grid';

/* Theme Fonts */
@font-face {
  font-family: 'payroll-icons';
  src: url('../../fonts/payroll-icons.eot?94887748');
  src: url('../../fonts/payroll-icons.eot?94887748#iefix') format('embedded-opentype'),
       url('../../fonts/payroll-icons.woff2?94887748') format('woff2'),
       url('../../fonts/payroll-icons.woff?94887748') format('woff'),
       url('../../fonts/payroll-icons.ttf?94887748') format('truetype'),
       url('../../fonts/payroll-icons.svg?94887748#payroll-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
$fontIcons: 'payroll-icons';

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* Styling */
body {
	.payroll-site {
		min-height: 100vh;
	}
	header {
		.payweek-button,
		.payweek-label {
			background: transparent;
			font-size: 2rem;
			padding: .25rem 0;
			&:active,
			&:hover {
				background: transparent;
			}
			& > i {
				position: relative;
				top: .25rem;
				margin: 0;
			}
			& > span {
				position: relative;
				top: .05rem;
				line-height: 1;
			}
		}
		span.payweek-label,
		button.payweek-label {
			width: 310px;
			text-align: center;
		}
		span.payweek-label {
			display: inline-block;
			line-height: 1;
		}
		.header-nav {
			text-align: left;
			padding-top: $global-padding;
			margin-bottom: calc($global-padding / 2);
			border-bottom: 2px solid;
		}
	}
	.login-screen {
		align-content: center;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
		.login-logo {
			height: 80px;
		}
		h1 {
			white-space: nowrap;
		}
	}
	.payroll-content {
		h2 {
			margin: 0;
			&.section-header {
				display: flex;
				position: relative;
				align-items: center;
				& > * {
					margin-left: calc($global-padding / 4)
				}
				& > .button,
				& > form > .button {
					margin: calc($global-padding / 2) calc($global-padding / 4);
					font-size: map-get($button-sizes, 'tiny');
					&.scroll-to-header {
						position: absolute;
						top: 0;
						right: 0;
						margin-right: 0;
					}
				}
			}
			&.flex-container {
				.hint-text {
					font-size: 1rem;
					@include flex-align-self(middle);
				}
				& + hr {
					margin-top: 1.25rem;
					margin-bottom: 0;
					max-width: unset;
				}
			}
			& > .h2Form {
				display: inline-block;
				
			}
			& > button,
			& > .h2Form > .button {
				margin-bottom: 0.5rem;
				padding: 0.5rem;
			}
		}
		hr {
			margin:0;
			max-width: unset;
			border-bottom: 2px solid color-pick-contrast($theme_background, ($theme_font_light, $theme_font_dark));
		}
		.payWeek-table {
			margin: 0;
			border: 2px solid;
			thead {
				background: transparent;
			}
			tbody {
				background: transparent;
				tr {
					background: transparent;
					td {
						border: 2px solid;
						padding: 0;
						cursor: pointer;
						width: 15%;
						&.weekend {
							max-width: 5%;
						}
						&.today {
							.dateDetails {
								.dateNum {
									padding: 0 .25rem;
									border-radius: 100%;
								}
							}
						}
						.weekHeader {
							display: flex;
							justify-content: center;
							align-items: center;
							min-width: 50px;
							border-right: 1px solid;
							font-weight: bold;
						}
						.dateDetails {
							padding: .5rem;
							.dateNum {
								font-size: 1.5rem;
								float: left;
							}
							.dailyHours,
							.dailyExpenses {
								display: block;
								text-align: right;
							}
							.absent {
								clear: both;
								.label,
								.badge {
									margin-right: 0.25rem;
									border: 2px solid;
									width: 25px;
									height: 25px;
								}
							}
						}
					}
				}
			}
			&.summary {
				thead {
					tr {
						th {
							&[class^="week"] {
								text-align: center;
							}
						}
					}
				}
				tbody {
					tr {
						th {
							white-space: nowrap;
						}
						td {
							text-align: center;
							max-width: 5%;
							&.weekend {
								max-width: 1%;
							}
							.tooltip-placeholder {
								display: block;
								line-height: 2.5rem;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
		.weekSummary,
		.timeoffSummary {
			.weekTotals {
				border: 2px solid color-pick-contrast($theme_background, ($theme_font_light, $theme_font_dark));
				margin: 1rem 0;
				h3 {
					margin: 0;
					line-height: 1;
					font-size: 1.25rem;
					font-weight: bold;
					padding: .25rem;
				}
				span {
					padding: .25rem;
				}
				hr {
					margin: .5rem 0;
				}
			}
			& > .hint-text {
				position: relative;
				bottom: 0.5rem;
			}
		}
		.pto-totals {
			margin-top: 1rem;
			ul {
				margin: 2.1rem 0;
				text-indent: 1rem;
				list-style: none;
				display: inline-block;
				li {
					hr {
						margin-top: 1rem;
					}
				}
			}
			div.pto-personal {
				margin: 0.75rem 0 2rem 0.75rem;
				display: inline-block;
				padding: .5rem;
				h3 {
					.button {
						margin-bottom: 0;
					}
				}
				div.current-requests {
					border-top: 2px solid;
					min-height: 1.5rem;
					background: rgba(0,0,0,0.5);
					padding: .5rem;
					.checkbox-switch-container {
						margin-top: .5rem;
						border: 1px solid;
						.checkbox-switch {
							left: 0;
						}
					}
				}
			}
		}
		.search-options {
			border-bottom: 2px solid;
			margin-bottom: 1rem;
		}
		.time-entry,
		.result-entry,
		.expense-entry {
			.hint-text {
				position: absolute;
				top: 1rem;
				right: 1rem;
				font-size: 0.75rem;
				font-style: italic;
				color: rgba(0,0,0,0.5);
				opacity: 0.75;
				& > .button {
					padding: 0;
					margin: 0 0 0.25rem;
					border: none;
				}
			}
			.mark-for-removal {
				display: inline-block;
				position: relative;
				& > .hint-text {
					top: 0.25rem;
					left: 2.5rem;
					right: unset;
					white-space: nowrap;
				}
			}
			label.button,
			button {
				border: 1px solid $dark-gray;
				margin-right: 0.5rem;
				&.copy-anywhere {
					margin-right: 0;
					position: absolute;
					top: 1rem;
					right: 1rem;
				}
			}
			dl {
				display: flex;
				flex-wrap: wrap;
				dt {
					flex-basis: 10%;
					margin: 0;
					line-height: 1rem;
				}
				dd {
					flex-basis: 90%;
					line-height: 1rem;
				}
			}
			fieldset {
				border: 1px inset;
				padding: 0 1rem 0.5rem 2rem;
				legend {
					margin: 0 0 0 -1.5rem;
					padding: 0 0.5rem;
					font-weight: bold;
				}
			}
			&.offset {
				button {
					border: 1px solid get-color('secondary');
				}
			}
		}
		.settings-form {
			label {
				&.color-option {
					display: inline-block;
					min-width: 30%;
					margin-right: 1rem;
				}
				&.checkbox-switch {
					margin-bottom: 0;
				}
			}
			.callout {
				& > label {
					display: flex;
					align-items: center;
					font-size: 1rem;
					& > span {
						white-space: nowrap;
						flex-basis: 25%;
					}
					& > input,
					& > select {
						margin: 0;
					}
				}
			}
			#light_or_dark {
				font-size: 1.5rem;
				bottom: 3px;
				position: relative;
				vertical-align: middle;
			}
			.label-text {
				margin-left: 0.5rem;
				vertical-align: middle;
			}
			.reset-help {
				margin: 0 0 1rem 1rem;
				font-size: 1rem;
				line-height: 2.5rem;
				display: inline-block;
				vertical-align: top;
			}
			#resetContainer {
				margin-top: 1rem;
				& + hr {
					margin-bottom: 1rem;
				}
			}
			#updateNotice {
				border-bottom: 2px solid;
				padding-bottom: 1rem;
			}
		}
		.search-form {
			margin-bottom: 1rem;
			padding: .5rem;
			border-bottom: 2px solid;
			h3 {
				margin: 0;
				line-height: 1;
				font-size: 1.25rem;
				font-weight: bold;
				padding-bottom: .25rem;
			}
			hr {
				width: calc(100% + 1rem);
				margin: .5rem -0.5rem;
			}
		}
		.search-results {
			.debug-panel {
				position: relative;
				top: 1rem;
			}
			.contract-block {
				& > .title {
					display: inline-block;
					padding: .5rem;
					border-radius: 15px 15px 0 0;
				}
				.contract-table {
					.sub-header {
						text-align: left;
					}
					td {
						padding-top: .25rem;
						padding-bottom: .25rem;
					}
					tr.table-break {
						& > td {
							height: 1rem;
							border-left: 2px solid transparent;
							border-right: 2px solid transparent;
						}
					}
				}
			}
		}
		.tabs {
			background: transparent;
			border: none;
			margin-top: 1rem;
			& > .tabs-title {
				& > a {
					border-radius: 15px 15px 0 0;
					position: relative;
					top: 2px;
					z-index: 4;
					border: 1px solid $theme_font_light;
					border-left: 0;
				}
				&.is-active > a {
					padding-bottom: calc(1.25rem + 1px);
					border-left: 1px solid $theme_font_light;
					z-index: 6;
				}
			}
		}
		.tabs-content {
			border: 1px solid $theme_font_light;
			position: relative;
			z-index: 5;
			dl {
				margin: 0;
				dt,
				dd {
					display: inline-block;
					width: 25%;
					vertical-align: top;
				}
				dd {
					width: 74%;
					border: 2px solid color-pick-contrast($theme_font, ($theme_font_light, $theme_font_dark));
					&.has-table {
						padding: 0;
						border: 0;
						table {
							margin-bottom: 0;
						}
					}
				}
			}
			dl > dd,
			.inset-panel {
				background: rgba(0,0,0,0.1);
				border: 2px solid $white;
				padding: 0.25rem;
				margin: 0 0 0.5rem;
			}
			.accordion {
				background: transparent;
				.accordion-title {
					font-size: 1.25rem;
				}
				.accordion-content {
					background: transparent;
					&.has-table {
						padding: 0;
						table {
							margin-bottom: 0;
						}
					}
				}
			}
			.archive-flex {
				display: flex;
				align-items: center;
				& .archive-count {
					font-size: 1.5rem;
					margin-left: .5rem;
					&.standard {
						color: get-color('success');
					}
					&.alert {
						color: get-color('alert');
					}
				}
			}
			.archive-table {
				.no-wrap {
					white-space: nowrap;
				}
			}
		}
	}
	#back-to-top {
		padding-right: 1rem;
		pointer-events: none;
		& > a {
			pointer-events: auto;
		}
	}
	.reveal {
		& > * {
			max-width: 600px;
			margin: 0 auto;
		}
		&.reveal-opened {
			display: flex !important;
			flex-direction: column;
			justify-content: center;
		}
		h2 {
			padding: 0 $global-padding;
			border: 1px solid;
			width: 100%;
		}
		.close-button-container {
			position: relative;
			width: 100%;
		}
		& > form {
			border: 1px solid;
			width: 100%;
			&.short-reveal-form {
				padding-top: 1rem;
				& > div.buttons {
					margin: 0 1rem;
				}
			}
			& > div.buttons {
				.button {
					border: 1px solid;
				}
			}
		}
		.abide-error {
			display: inline-block !important;
			vertical-align: middle;
			margin: 0 0 1rem .5rem;
			& > .form-error {
				margin: 0;
			}
		}
		.accordion {
			.accordion-item {
				.accordion-title {
					.sub-text {
						float: right;
						margin-right: 1rem;
					}
				}
				.accordion-content {
					.label-cell {
						align-self: center;
						label {
							margin-bottom: .5rem;
						}
					}
					.field-cell {
						margin-bottom: .5rem;
						.button,
						textarea,
						.input-group,
						input[type="text"],
						input[type="number"] {
							margin-bottom: 0;
						}
					}
					.button {
						border: 1px solid;
					}
				}
				&.is-active {
					.accordion-title {
						cursor: default;
					}
				}
			}
		}
	}
	#requestPTOEntry {
		.checkbox-switch-container {
			float: left;
			margin-bottom: 3px;
			.checkbox-switch {
				border: 2px solid;
			}
		}
		.pto-table-spacing {
			margin-bottom: 1px;
			& > label  {
				margin-bottom: 0;
			}
		}
		.pto-table-notes {
			margin-top: .5rem;
		}
		.pto-usage-table {
			margin-left: 2.3rem;
			margin-bottom: 0;
			width: calc(100% - 2.3rem);
			th {
				font-size: 0.8rem;
				width: 33%;
				text-align: right;
			}
			td {
				width: 33%;
				padding-top: 1px;
				padding-bottom: 1px;
				text-align: right;
			}
			&.disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}
		.pto_request_type_m_desc {
			width: calc(100% - 2.3rem);
			padding-top: 1px;
			padding-bottom: 1px;
			height: auto;			
		}
	}
/* Misc. */
	#loadingScreen {
		max-width: unset;
		width: 100%;
		height: 100vh;
		top: 0 !important;
		border: none;
		justify-content: center;
		align-items: center;
		&.is-open {
			display: block;
		}
		& > div {
			&.loading-icon {
				font-size: 3.5rem;
				&::after {
					content: 'Loading';
					display: block;
					font-size: 1rem;
					text-align: center;
				}
			}
		}
	}
	label.button {
		padding: calc(.5rem - 2px);
	}
	.checkbox-switch-container {
		position: relative;
		& > label.checkbox-switch {
			position: relative;
			z-index: 2;
			left: 1px;
		}
		& > input[type=checkbox] {
			scale: 1.75;
			margin: 0;
		}
		& > span.help-text {
			display: block;
			width: 100%;
			text-align: center;
			position: absolute;
			top: calc(.5rem - 1px);
			left: 0;
			padding: calc(.25rem - 1px);
			z-index: 1;
			cursor: pointer;
			line-height: 1.35rem;
			border: 1px solid $theme_good;
		}
		&.disabled {
			opacity: 0.5;
			& > label.checkbox-switch {
				cursor: not-allowed;
			}
		}
	}
	.number-spinner {
		position: relative;
		& > .spinner-button {
			position: absolute;
			right: 0;
			top: 1px;
			margin: 0;
			padding: 0.25rem 0.5rem;
			border: 1px solid;
			&.down {
				top: unset;
				bottom: 1px;
			}
		}
	}
	.flatpickr-calendar {
		.flatpickr-time,
		.flatpickr-current-month {
			& > .numInputWrapper {
				width: unset;
				position: relative;
				& > .arrowUp,
				& > .arrowDown {
					position: absolute;
					right: 1px;
					top: 1px;
					margin: 0;
					padding: 0.35rem;
					border: 1px solid;
					opacity: 1;
					width: 1.25rem;
					&.arrowDown {
						top: unset;
						bottom: 1px;
					}
				}
			}
		}
		.flatpickr-time {
			.flatpickr-time-separator {
				padding: 0 1rem 0 .9rem;
			}
		}
		.flatpickr-current-month {
			padding: 0;
			display: flex;
			justify-content: space-between;
			& > select {
				height: inherit;
				flex-grow: 1; 
			}
			& > .numInputWrapper {
				& > .cur-year {
					line-height: unset;
					height: 100%;
				}
			}
		}
		.flatpickr-prev-month,
		.flatpickr-next-month {
			svg {
				position: relative;
				bottom: 3px;
			}
		}
	}
	.caution-text {
		display: block;
		margin: 1rem 0;
		border: 2px solid;
	}
	.pr-flatpickr-input {
		&[readonly] {
			cursor: default;
		}
	}
	.flatpickr-monthDropdown-months {
		width: auto;
	}
	.pay-icon {
		font-family: $fontIcons;
		font-style: normal;
		font-weight: normal;
		speak: never;

		display: inline-block;
		text-decoration: inherit;
		width: 1em;
		margin-right: .2em;
		text-align: center;
		/* opacity: .8; */

		/* For safety - reset parent styles, that can break glyph codes*/
		font-variant: normal;
		text-transform: none;

		/* fix buttons height, for twitter bootstrap */
		line-height: 1em;

		/* Animation center compensation - margins should be symmetric */
		/* remove if not needed */
		margin-left: .2em;

		/* you can be more comfortable with increased icons size */
		/* font-size: 120%; */

		/* Font smoothing. That was taken from TWBS */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		&.animate-spin {
			-moz-animation: spin 2s infinite linear;
			-o-animation: spin 2s infinite linear;
			-webkit-animation: spin 2s infinite linear;
			animation: spin 2s infinite linear;
			display: inline-block;
		}

		/* Uncomment for 3D effect */
		/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
		
		&.icon-empty:before { color: transparent !important; content: '\e80b'; } /* '' */

		/* Icon List */
		&.icon-user:before { content: '\e800'; } /* '' */
		&.icon-cancel:before { content: '\e801'; } /* '' */
		&.icon-help:before { content: '\e802'; } /* '' */
		&.icon-info:before { content: '\e803'; } /* '' */
		&.icon-alert:before { content: '\e804'; } /* '' */
		&.icon-down:before { content: '\e805'; } /* '' */
		&.icon-left:before { content: '\e806'; } /* '' */
		&.icon-right:before { content: '\e807'; } /* '' */
		&.icon-up:before { content: '\e808'; } /* '' */
		&.icon-settings:before { content: '\e809'; } /* '' */
		&.icon-location:before { content: '\e80a'; } /* '' */
		&.icon-check:before { content: '\e80b'; } /* '' */
		&.icon-trash:before { content: '\e80c'; } /* '' */
		&.icon-plus:before { content: '\e80d'; } /* '' */
		&.icon-minus:before { content: '\e80e'; } /* '' */
		&.icon-switch:before { content: '\e80f'; } /* '' */
		&.icon-key:before { content: '\e810'; } /* '' */
		&.icon-calendar:before { content: '\e811'; } /* '' */
		&.icon-edit:before { content: '\e812'; } /* '' */
		&.icon-flag:before { content: '\e813'; } /* '' */
		&.icon-clock:before { content: '\e814'; } /* '' */
		&.icon-logout:before { content: '\e816'; } /* '' */
		&.icon-search:before { content: '\e817'; } /* '' */
		&.icon-sun:before { content: '\e819'; } /* '' */
		&.icon-moon:before { content: '\e81a'; } /* '' */
		&.icon-maint:before { content: '\e81b'; } /* '' */
		&.icon-group:before { content: '\e81c'; } /* '' */
		&.icon-loading:before { content: '\e834'; } /* '' */
		&.icon-copy:before { content: '\f0c5'; } /* '' */
		&.icon-magic:before { content: '\f0d0'; } /* '' */
		&.icon-paste:before { content: '\f0ea'; } /* '' */
		&.icon-file-basic:before { content: '\f0f6'; } /* '' */
		&.icon-dollar:before { content: '\f155'; } /* '' */
		&.icon-file-pdf:before { content: '\f1c1'; } /* '' */
		&.icon-file-image:before { content: '\f1c5'; } /* '' */
		&.icon-copyright:before { content: '\f1f9'; } /* '' */
		&.icon-lock:before { content: '\f510'; } /* '' */
	}
	.debug-panel {
		max-height: 75vh;
		height: fit-content;
		overflow: hidden auto;
		@include callout-base();
		@include xy-cell();
		&.thin {
			padding: 0.5rem;
			width: auto;
			display: inline-block;
			margin: 0 0.5rem 0.5rem 0;
		}
		&.alert {
			@include callout-style(get-color('alert'));
		}
		&.warning {
			@include callout-style(get-color('warning'));
		}
		&.highlight {
			@include callout-style(get-color('highlight'));
		}
		&.primary {
			@include callout-style(get-color('primary'));
		}
		&.success {
			@include callout-style(get-color('success'));
		}
		&.info {
			@include callout-style(get-color('info'));
		}
		&.misc {
			@include callout-style(get-color('misc'));
		}
		&.secondary {
			@include callout-style(get-color('secondary'));
		}
	}
	.color-swatch {
		border: 1px solid #000;
		&.white { background: $white; }
		&.light { background: $light-gray; }
		&.medium { background: $medium-gray; }
		&.dark { background: $dark-gray; }
		&.black { background: $black; }
	}
	select[size] {
		height: unset;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}
	select {
		& > optgroup {
			color: transparent;
			line-height: 0;
			font-size: 0;
			& > option {
				color: $black;
				font-size: 1rem;
				padding-left: 0.25rem;
			}
		}
	}
	input[type="text"],
	input[type="date"],
	input[type="color"],
	input[type="number"],
	input[type="password"],
	textarea,
	select {
		&:disabled {
			opacity: 0.5;
		}
	}
	.callout {
		margin: 1rem 0;
	}
	table {
		thead {
			border: none;
			th {
				border: 2px solid color-pick-contrast($theme_font, ($theme_font_light, $theme_font_dark));
				&.empty-cell {
					border: none;
				}
			}
		}
		tbody {
			border: 2px solid color-pick-contrast($theme_font, ($theme_font_light, $theme_font_dark));
			& tr:not(:first-child) {
				th {
					border-top: 2px solid color-pick-contrast($theme_good_dark, ($theme_font_light, $theme_font_dark));
				}
				td {
					border-top: 2px solid color-pick-contrast($theme_font, ($theme_font_light, $theme_font_dark));
				}
			}
			th {
				border: 2px solid;
			}
			th,
			td.numeric {
				text-align: right;
			}
			td {
				&:not(:first-child) {
					border-left: 2px solid color-pick-contrast($theme_font, ($theme_font_light, $theme_font_dark));
				}
			}
		}
	}
	footer {
		margin-top: 1rem;
	}
/* Breakpoints */
	@include breakpoint(small only) {
//		background: get-color('alert') !important; // Red
		header {
			& > form:not(.header-nav) {
				text-align: center;
				padding: 0 !important;
			}
			.payweek-button,
			.payweek-label {
				font-size: clamp(1rem, 8vw, 2rem);
			}
			span.payweek-label,
			button.payweek-label {
				width: clamp(250px, 80%, 300px);
			}
		}
		.login-screen {
			form {
				max-width: min-content;
				min-width: 95vw;
			}
		}
		.payroll-content {
			.payWeek-table {
				tbody {
					tr {
						td {
							width: 100%;
							&.weekend {
								max-width: unset;
							}
							.dateDetails {
								width: calc(100% - 50px);
								display: flex;
								justify-content: space-between;
								align-items: center;
								.dateNum {
									font-size: 1.25rem;
								}
								.dailyHours {
									order: 2;
									flex-grow: 1;
								}
								.dailyExpenses {
									order: 3;
									border-left: 2px solid;
									margin-left: 0.5rem;
									padding-left: 0.5rem;
									&.empty {
										border-left: none;
										margin: 0;
										padding: 0;
									}
								}
							}
						}
					}
				}
			}
			.time-entry,
			.result-entry,
			.expense-entry {
				dl {
					display: block;
					dt {
						float: left;
					}
					dd {
						flex-basis: 100%;
						text-align: right;
					}
				}
			}
			.tabs > .tabs-title > a {
				padding: 1.25rem 0.75rem;
			}
			.tabs-content {
				dl {
					dt,
					dd {
						width: 100%;
					}
				}
			}
		}
		.reveal {
			.field-cell {
				.checkbox-switch-container {
					& > label.checkbox-switch {
						margin-bottom: 0.5rem !important;
					}
				}
			}
		}
		.checkbox-switch-container {
			& > span.help-text {
				position: relative;
				top: -0.25rem;
				display: inline-block;
				width: fit-content;
				padding-left: 1rem;
				padding-right: 1rem;
				margin-top: 0;
				margin-bottom: 0.5rem !important;
			}
		}
		.close-button {
			font-size: 1rem;
			top: 0.5rem;
			right: 0.25rem;
		}
	}		
	@include breakpoint(medium up) {
//		background: get-color('warning') !important; // Orange
		.login-screen {
			form {
				max-width: min-content;
			}
		}
		.payroll-content {
			.tabs > .tabs-title > a {
				padding: 1.25rem 0.75rem;
			}
		}
	}		
	@include breakpoint(large up) {
//		background: get-color('highlight') !important; // Yellow
	}		
	@include breakpoint(xlarge up) {
//		background: get-color('success') !important; //Green
	}		
	@include breakpoint(xxlarge only) {
//		background: get-color('info') !important; // Blue
	}		
}